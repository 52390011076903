<template>
  <div>
    <advertisement-top-banner />
    <div v-if="$store.state.core.websiteMode === 'store'">
      <store />
    </div>
    <div v-else>
      <product />
    </div>
  </div>
</template>

<script>
import product from "./homePages/product.vue";
import store from "./homePages/store.vue";

export default {
  components: {
    product,
    store,
  },
  data() {
    return {
      theme: {},
      marketName: "",
    };
  },
  mounted() {
    this.$store.commit("theme/updateTheme", {});
    this.$store.commit("theme/updateName", "");
  },
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "home"
  }, [_c('b-row', {
    staticClass: "mx-3"
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }), _c('b-col', {
    staticClass: "search-options",
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.options2
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('b-col', {
    staticClass: "search-options",
    attrs: {
      "md": "3"
    }
  }, [_vm.showMarketOption ? _c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.marketOptions
    },
    on: {
      "input": _vm.distanceFilter
    },
    model: {
      value: _vm.selectedMarketOption,
      callback: function callback($$v) {
        _vm.selectedMarketOption = $$v;
      },
      expression: "selectedMarketOption"
    }
  }) : _c('div', {
    staticClass: "d-flex mt-1"
  }, [_c('b-form-input', {
    staticClass: "search-storeinput",
    attrs: {
      "placeholder": "Search Store..."
    },
    model: {
      value: _vm.storeName,
      callback: function callback($$v) {
        _vm.storeName = $$v;
      },
      expression: "storeName"
    }
  }), _c('button', {
    staticClass: "col search-button mt-3 border-radius-r-40",
    on: {
      "click": _vm.storeResult
    }
  }, [_c('b-icon', {
    staticClass: "margin-top-4",
    attrs: {
      "icon": "search"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "search-options",
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.options
    },
    on: {
      "input": _vm.distanceFilter
    },
    model: {
      value: _vm.selectedDistance,
      callback: function callback($$v) {
        _vm.selectedDistance = $$v;
      },
      expression: "selectedDistance"
    }
  })], 1)], 1), _c('sections-slider', {
    attrs: {
      "list": _vm.newStore,
      "subTitle": "Stores",
      "hasBackground": true,
      "showTitle": true,
      "hasButton": false,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box product-listing-div"
        }, [_c('router-link', {
          staticStyle: {
            "text-decoration": "none",
            "color": "inherit"
          },
          attrs: {
            "to": {
              name: "shopDetails",
              params: {
                id: listItem._id,
                items: listItem.office.officeName
              }
            }
          }
        }, [_c('items-item', {
          attrs: {
            "itemWhiteBg": true,
            "product": listItem
          }
        })], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="home">
    <sections-slider
      v-if="marketList && marketList.length"
      :list="marketList"
      subTitle="Our Market"
      :showTitle="true"
      :hasInnerBackground="true"
      :hasButton="false"
      :withSlider="true"
    >
      <template slot="default" slot-scope="{ listItem }">
        <div class="item-box">
          <items-item
            :itemLogo="true"
            :product="listItem"
            :incrementDecrementKey="false"
            :marketRoute="{
              name:
                listItem.name == 'Health Care'
                  ? `health-care`
                  : `market-view` && listItem.name == 'Bill Payment'
                  ? billMarketRouterName
                  : `market-view` && listItem.name == 'Clothes & Fashion'
                  ? `fashionMarket`
                  : `market-view`,
              params: {
                market: listItem.name,
                category:
                  listItem.name == 'Bill Payment'
                    ? billMarketRouterName
                    : `market-view` && listItem.name == 'Health Care'
                    ? healthMarketRouterName
                    : `market-view`,
              },
            }"
          ></items-item>
        </div>
      </template>
    </sections-slider>
    <b-container>
      <h3 class="subtitle-style">Bill Payment</h3>
      <div>
        <b-col
          md="4"
          class="bill-market-section"
          v-for="category in billCategoryList"
          :key="category._id"
        >
          <router-link
            class="bill-payment-link"
            :to="{
              name: category.name,
              params: { category: category.name },
            }"
          >
            <div class="d-flex align-items-center bill-payment-box">
              <b-img
                class="bill-image mr-5"
                :src="
                  category && category.images ? category.images.thumbnail : ''
                "
              ></b-img>
              <p>{{ category ? category.name : "" }}</p>
            </div>
          </router-link>
        </b-col>
      </div>
    </b-container>
    <!-- <div class="text-center">
      <img style="width: 85%" src="img/mid-banners.png" />
    </div> -->
    <b-container>
      <template v-if="$options.components['advertisement-home-offer']">
        <advertisement-home-offer />
      </template>
    </b-container>
    <div class="bg-colors mb-5">
      <sections-slider
        :list="newStore"
        subTitle="New Stores"
        :hasBackground="false"
        :showTitle="true"
        :hasButton="true"
        class="stores-slider mobile-view-stores"
        :withSlider="true"
        :viewMorePage="{
          name: `product-listing`,
          params: {
            items: `New Stores`,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: `shopDetails`,
                params: {
                  id: listItem._id,
                  items: listItem.office.officeName,
                },
              }"
            >
              <items-item :itemWhiteBg="true" :product="listItem"></items-item>
            </router-link>
          </div>
        </template>
      </sections-slider>
    </div>
    <div class="bg-colors mb-5">
      <sections-slider
        :list="newArrivals"
        subTitle="New Arrivals"
        :showTitle="true"
        :hasBackground="false"
        :hasButton="true"
        class="suggested-slider"
        :withSlider="true"
        :viewMorePage="{
          name: `new-arrivals-listing`,
          params: {
            items: `New Arrivals`,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
    <div class="bg-colors mb-5">
      <sections-slider
        :list="itemSuggestions"
        subTitle="Suggested For You"
        :hasBackground="false"
        :showTitle="true"
        :hasButton="true"
        class="suggested-slider"
        :withSlider="true"
        :viewMorePage="{
          name: `product-listing`,
          params: {
            items: `Suggested For You`,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
    <div class="bg-colors mb-5">
      <sections-slider
        v-if="trendingItems.length"
        :list="trendingItems"
        subTitle="What's Trending"
        :showTitle="true"
        :hasBackground="false"
        :hasButton="true"
        class="suggested-slider"
        :withSlider="true"
        :viewMorePage="{
          name: `product-listing`,
          params: {
            items: `What's Trending`,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
    <!-- <template v-if="$options.components['advertisement-offer']">
      <advertisement-offer />
    </template>-->
    <b-container>
      <template v-if="$options.components['advertisement-home-offer-middle']">
        <advertisement-home-offer-middle />
      </template>
    </b-container>
    <div
      class="bg-colors mb-5"
      v-for="marketItem in Object.keys(marketListData)"
      :key="marketItem"
    >
      <sections-slider
        :list="marketListData[marketItem]"
        :subTitle="marketItem"
        :hasButton="true"
        :onlyTitle="true"
        :showTitle="true"
        :itemSubTitle="true"
        :hasBackground="false"
        class="suggested-slider"
        :withSlider="true"
        v-if="marketListData[marketItem].length"
        :viewMorePage="{
          name: `market-view`,
          params: {
            market: marketItem,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="
                listItem.Market == 'Home Improvement' ||
                listItem.Market == 'Health Care' ||
                listItem.Market == 'Clothes & Fashion' ||
                listItem.Market == 'Bill Payment'
                  ? false
                  : true
              "
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
    <b-modal
      id="add-cashback-account"
      size="md"
      centered
      title=""
      hide-header
      hide-footer
    >
      <v-container>
        <b-col md="12">
          <div class="text-center m-2">
            <h3>Add Cashback Account</h3>
            <p class="mt-3">
              To recieve cashbacks please add your bank details under cashback
              accounts.
            </p>
          </div>
        </b-col>
        <div class="d-flex justify-content-center mt-3">
          <button
            @click="$bvModal.hide('add-cashback-account')"
            type="button"
            class="btn btn-outline-primary mr-3 pr-5 pl-5"
          >
            <span class="button-text">Skip Now</span>
          </button>
          <button
            @click="addAccount"
            type="button"
            class="btn btn-primary ml-3"
          >
            <span class="button-text pr-5 pl-5">Add Now</span>
          </button>
        </div>
      </v-container>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      billMarketId: null,
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      trendingItems: (state) => state["home"].trendingItems,
      itemSuggestions: (state) => state["home"].itemSuggestions,
      newArrivals: (state) => state["home"].newArrivals,
      newStore: (state) => state["home"].newStores,
      marketList: (state) => state["home"].marketList,
      marketListData: (state) => state["home"].marketListData,
      currentPosition: (state) => state.location.currentPosition,
      homeLocation: (state) => state["home"].defaultLocation,
      billCategoryList: (state) => state["home"].billCategoryList,
      billMarketRouterName: (state) => state["home"].billMarketRouterName,
      healthMarketRouterName: (state) =>
        state["healthCare"].healthMarketRouterName,
    }),
  },
  watch: {
    homeLocation() {
      this.fetchStore();
    },
  },
  methods: {
    ...mapActions({
      fetchAllItemsData: "home/fetchAllItems",
      fetchNewStoreData: "home/fetchNewStores",
      fetchMarketItemsData: "home/fetchMarketItemsData",
      fetchMarket: "home/fetchMarketList",
      fetchBillPaymentCategoryData: "home/fetchBillPaymentCategoryData",
    }),
    fetchStore() {
      const payload = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: this.currentPosition?.Lat || 0,
        lng: this.currentPosition?.Lng || 0,
        radius: 6000,
        newlyAdded: "n",
        refresh: true,
      };
      this.fetchNewStoreData(payload);
    },
    fetchItemsData() {
      this.fetchAllItemsData();
    },
    fetchMarketdata() {
      this.fetchMarketItemsData();
    },
    fetchBillPaymentCategory() {
      this.fetchBillPaymentCategoryData();
    },
    setBillMarketId() {
      const selectBillMarket = this.marketList.find(
        (e) => e.name === "Bill Payment"
      );
      this.billMarketId = selectBillMarket._id;
    },
    addAccount() {
      this.$bvModal.hide("add-cashback-account");
      this.$router.push("/cashback-accounts");
    },
  },

  async mounted() {
    await this.setBillMarketId();
    await this.fetchBillPaymentCategory();
    await this.$store.dispatch(
      "healthCare/fetchHealthCareCategoryData",
      "628371f94b367714f38740e6"
    );
    try {
      await this.$store.dispatch(
        "profile/getAccountDetails",
        this.userInfo._id
      );
    } catch (error) {
      if (this.$store.state["home"].showCashbackPopup) {
        this.$bvModal.show("add-cashback-account");
        this.$store.state["home"].showCashbackPopup = false;
      }
    }
    await this.fetchMarketdata();
    await this.fetchStore();
    if (
      this.$store.state["home"].itemSuggestions.length === 0 ||
      this.$store.state["home"].newArrivals.length === 0 ||
      this.$store.state["home"].trendingItems.length === 0
    ) {
      await this.fetchItemsData();
    }
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.bill-market-section {
  display: inline-block;
}

.subtitle-style {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  text-transform: capitalize;
  color: #000000;
}

.bill-payment-box {
  background: #f8f8f8;
  color: #272727;
  border-radius: 5px;
  border: 1px solid #d9f6ff;
  position: relative;
  z-index: 0;
}

.bill-payment-box:hover {
  background: #d9f6ff;
  box-shadow: #d9f6ff;
  border: 1px solid #d9f6ff;
  color: #272727;
}

.bill-payment-box::before {
  position: absolute;
  background: #d9f6ff;
  width: 100px;
  content: "";
  height: 80px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.bill-payment-box:hover::before {
  position: absolute;
  background: #fefefe;
  width: 100px;
  content: "";
  height: 80px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.bill-payment-link {
  text-decoration: none;
  color: #272727;
}
.bill-payment-box p {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0;
}

.bill-img-box {
  box-sizing: content-box !important;
}

.bill-image {
  width: 80px;
  padding: 10px;
  z-index: 2;
}

.stores-slider .item-box {
  margin: 0 12px;
  width: 208px;
  /* background: #ffffff; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}
.suggester-div {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}

.suggested-slider .suggester-div:hover {
  /* border: 1px solid #272727; */
  box-shadow: 0px 10px 20px 5px rgba(184, 178, 178, 0.5);
}

.marketitem-slider .item-box {
  margin: 0 12px;
  width: 208px;
  display: block;
}
.bg-colors {
  background: #f8f8f8;
}
@media (max-width: 768px) {
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none !important;
  }

  .marketitem-slider .item-box {
    width: 266px;
  }

  .stores-slider .item-box {
    margin: 0 12px;
    width: 136px;
  }
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_vm.marketList && _vm.marketList.length ? _c('sections-slider', {
    attrs: {
      "list": _vm.marketList,
      "subTitle": "Our Market",
      "showTitle": true,
      "hasInnerBackground": true,
      "hasButton": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          attrs: {
            "itemLogo": true,
            "product": listItem,
            "incrementDecrementKey": false,
            "marketRoute": {
              name: listItem.name == 'Health Care' ? "health-care" : "market-view" && listItem.name == 'Bill Payment' ? _vm.billMarketRouterName : "market-view" && listItem.name == 'Clothes & Fashion' ? "fashionMarket" : "market-view",
              params: {
                market: listItem.name,
                category: listItem.name == 'Bill Payment' ? _vm.billMarketRouterName : "market-view" && listItem.name == 'Health Care' ? _vm.healthMarketRouterName : "market-view"
              }
            }
          }
        })], 1)];
      }
    }], null, false, 1844745331)
  }) : _vm._e(), _c('b-container', [_c('h3', {
    staticClass: "subtitle-style"
  }, [_vm._v("Bill Payment")]), _c('div', _vm._l(_vm.billCategoryList, function (category) {
    return _c('b-col', {
      key: category._id,
      staticClass: "bill-market-section",
      attrs: {
        "md": "4"
      }
    }, [_c('router-link', {
      staticClass: "bill-payment-link",
      attrs: {
        "to": {
          name: category.name,
          params: {
            category: category.name
          }
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center bill-payment-box"
    }, [_c('b-img', {
      staticClass: "bill-image mr-5",
      attrs: {
        "src": category && category.images ? category.images.thumbnail : ''
      }
    }), _c('p', [_vm._v(_vm._s(category ? category.name : ""))])], 1)])], 1);
  }), 1)]), _c('b-container', [_vm.$options.components['advertisement-home-offer'] ? [_c('advertisement-home-offer')] : _vm._e()], 2), _c('div', {
    staticClass: "bg-colors mb-5"
  }, [_c('sections-slider', {
    staticClass: "stores-slider mobile-view-stores",
    attrs: {
      "list": _vm.newStore,
      "subTitle": "New Stores",
      "hasBackground": false,
      "showTitle": true,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "product-listing",
        params: {
          items: "New Stores"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('router-link', {
          staticStyle: {
            "text-decoration": "none",
            "color": "inherit"
          },
          attrs: {
            "to": {
              name: "shopDetails",
              params: {
                id: listItem._id,
                items: listItem.office.officeName
              }
            }
          }
        }, [_c('items-item', {
          attrs: {
            "itemWhiteBg": true,
            "product": listItem
          }
        })], 1)], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "bg-colors mb-5"
  }, [_c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.newArrivals,
      "subTitle": "New Arrivals",
      "showTitle": true,
      "hasBackground": false,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "new-arrivals-listing",
        params: {
          items: "New Arrivals"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var listItem = _ref3.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "bg-colors mb-5"
  }, [_c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.itemSuggestions,
      "subTitle": "Suggested For You",
      "hasBackground": false,
      "showTitle": true,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "product-listing",
        params: {
          items: "Suggested For You"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var listItem = _ref4.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "bg-colors mb-5"
  }, [_vm.trendingItems.length ? _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.trendingItems,
      "subTitle": "What's Trending",
      "showTitle": true,
      "hasBackground": false,
      "hasButton": true,
      "withSlider": true,
      "viewMorePage": {
        name: "product-listing",
        params: {
          items: "What's Trending"
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var listItem = _ref5.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 113096431)
  }) : _vm._e()], 1), _c('b-container', [_vm.$options.components['advertisement-home-offer-middle'] ? [_c('advertisement-home-offer-middle')] : _vm._e()], 2), _vm._l(Object.keys(_vm.marketListData), function (marketItem) {
    return _c('div', {
      key: marketItem,
      staticClass: "bg-colors mb-5"
    }, [_vm.marketListData[marketItem].length ? _c('sections-slider', {
      staticClass: "suggested-slider",
      attrs: {
        "list": _vm.marketListData[marketItem],
        "subTitle": marketItem,
        "hasButton": true,
        "onlyTitle": true,
        "showTitle": true,
        "itemSubTitle": true,
        "hasBackground": false,
        "withSlider": true,
        "viewMorePage": {
          name: "market-view",
          params: {
            market: marketItem
          }
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var listItem = _ref6.listItem;
          return [_c('div', {
            staticClass: "item-box"
          }, [_c('items-item', {
            staticClass: "suggester-div",
            attrs: {
              "itemNoBg": true,
              "product": listItem,
              "incrementDecrementKey": listItem.Market == 'Home Improvement' || listItem.Market == 'Health Care' || listItem.Market == 'Clothes & Fashion' || listItem.Market == 'Bill Payment' ? false : true
            }
          })], 1)];
        }
      }], null, true)
    }) : _vm._e()], 1);
  }), _c('b-modal', {
    attrs: {
      "id": "add-cashback-account",
      "size": "md",
      "centered": "",
      "title": "",
      "hide-header": "",
      "hide-footer": ""
    }
  }, [_c('v-container', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center m-2"
  }, [_c('h3', [_vm._v("Add Cashback Account")]), _c('p', {
    staticClass: "mt-3"
  }, [_vm._v(" To recieve cashbacks please add your bank details under cashback accounts. ")])])]), _c('div', {
    staticClass: "d-flex justify-content-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-3 pr-5 pl-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('add-cashback-account');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }, [_vm._v("Skip Now")])]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addAccount
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5"
  }, [_vm._v("Add Now")])])])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }